::-webkit-scrollbar-track {
  background: rgb(212, 212, 212);
}

::-webkit-scrollbar-thumb {
  background: rgb(47, 47, 47);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(30, 30, 30);
}

.cursor-crosshair {
  cursor: crosshair;
}

.overscroll {
  overscroll-behavior: none;
}
